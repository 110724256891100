import BackendService from "./BackendService";
import html2canvas from "html2canvas";

export default class Util {

    static loading = false;

    static loadingCount = 0;

    static version = "";

    static refreshPage = false

    static menuIsOpen = false

    static cachedRemoteStorage = {}

    static checkVersion() {
        return BackendService.get("/version/front").then((resp) => {
            return resp
        })
    }

    static getRemoteStorage(key) {
        if (Object.prototype.hasOwnProperty.call(this.cachedRemoteStorage, key)) {
            return Promise.resolve(this.cachedRemoteStorage[key]);
        } else {
            const payload = { "key": key };
            
            return BackendService.post("/remote-storage/get-item", payload).then((resp) => {
                this.cachedRemoteStorage[key] = resp;
                return resp;
            });
        }
    }
    
    static setRemoteStorage(key, value) {
        var payload = {}
        payload[key] = value

        return BackendService.post("/remote-storage/set-item", payload).then((resp) => {
            this.cachedRemoteStorage[key] = value
            return resp
        })
    }

    static replaceAll(str, find, replace) {
        if (find === '') {
            return str.toLowerCase();
        }

        const regex = new RegExp(Util.escapeRegExp(find), 'gi');
        return str.toLowerCase().replace(regex, replace);
    }

    static escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    static getClipboard() {
        return navigator.clipboard.readText()

    }

    static loadRequest() {
        if (Util.loadingCount == 0) {
            Util.loading = true
        }
        Util.loadingCount++
    }

    static loadedRequest() {
        Util.loadingCount--
        if (Util.loadingCount == 0) {
            Util.loading = false
        }
    }

    static debounce(f, timeout = 300) {
        let timer
        return (...args) => {
            clearTimeout(timer)
            timer = setTimeout(() => { f.apply(this, args) }, timeout)
        }
    }

    static stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    static emailToName(email) {
        if (email) {
            const parts = email.split('@');
            const name = parts[0];

            const formattedName = name.replace(/[-.]\w/g, match => ` ${match.charAt(1).toUpperCase()}`);

            return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
        } else {
            return email
        }
    }

    // static encodeQueryData(data) {
    //     const ret = [];
    //     for (let d in data){
    //         ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    //     }
    //     return ret.join('&');
    // }

    static encodeQueryData(data) {
        const ret = [];
        for (let d in data) {
            if (Object.prototype.hasOwnProperty.call(data, d)) {
                const value = data[d] === null || data[d] === undefined ? '' : data[d];
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
            }
        }
        return ret.join('&');
    }

    static diffObject(obj1, obj2) {
        if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
            return obj1;
        }
        var diffs = {};
        var key;
        var arraysMatch = function (arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (var i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;

        };
        var compare = function (item1, item2, key) {
            var type1 = Object.prototype.toString.call(item1);
            var type2 = Object.prototype.toString.call(item2);
            if (type2 === '[object Undefined]') {
                diffs[key] = null;
                return;
            }
            if (type1 !== type2) {
                diffs[key] = item2;
                return;
            }
            if (type1 === '[object Object]') {
                var objDiff = Util.diffObject(item1, item2);
                if (Object.keys(objDiff).length > 0) {
                    diffs[key] = objDiff;
                }
                return;
            }
            if (type1 === '[object Array]') {
                if (!arraysMatch(item1, item2)) {
                    diffs[key] = item2;
                }
                return;
            }
            if (type1 === '[object Function]') {
                if (item1.toString() !== item2.toString()) {
                    diffs[key] = item2;
                }
            } else {
                if (item1 !== item2) {
                    diffs[key] = item2;
                }
            }

        };
        for (key in obj1) {
            if (Object.prototype.hasOwnProperty.call(obj1, key)) {
                compare(obj1[key], obj2[key], key);
            }
        }
        for (key in obj2) {
            if (Object.prototype.hasOwnProperty.call(obj2, key)) {
                if (!obj1[key] && obj1[key] !== obj2[key]) {
                    diffs[key] = obj2[key];
                }
            }
        }
        return diffs;
    }

    static listToTree(list) {
        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].RequestedObject.Id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.RequestedObject.ParentId !== null) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.RequestedObject.ParentId]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    static randomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    static copyToClipboard(textHTML, textPlain) {
        var clipboardItem = new
            window.ClipboardItem({
                'text/html': new Blob([textHTML],
                    { type: 'text/html' }),
                'text/plain': new Blob([textPlain],
                    { type: 'text/plain' })
            });
        navigator.clipboard.write([clipboardItem]).then(
            function () {
                /* success */
            },
            function () {
                /* failure */
            }
        );
    }

    static getSelectionHtml() {
        var html = "";
        if (typeof window.getSelection != "undefined") {
            var sel = window.getSelection();
            if (sel.rangeCount) {
                var container = document.createElement("div");
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }
                html = container.innerHTML;
            }
        } else if (typeof document.selection != "undefined") {
            if (document.selection.type == "Text") {
                html = document.selection.createRange().htmlText;
            }
        }
        return html;
    }

    static parseDate(dateString) {
        // Regex para extrair os componentes da data
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var match = dateString.match(pattern);
    
        if (match) {
            // Extraindo os componentes da data
            var day = match[1];
            var month = match[2] - 1; // Em JavaScript, o mês é indexado a partir de 0 (0 = Janeiro, 11 = Dezembro)
            var year = match[3];
    
            // Criando um objeto Date com os componentes extraídos
            return new Date(year, month, day);
        } else {
            throw new Error("Formato de data inválido. Use dd/mm/yyyy.");
        }
    }

    static formatDate(dateInput, complete) {

        let date = new Date(dateInput);
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        if (hour < 10) {
            hour = '0' + hour
        }
        if (minutes < 10) {
            minutes = '0' + minutes
        }

        if (!complete) {
            return day + '/' + month + '/' + year
        }
        return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes
    }

    static debugs = []
    static logs = []
    static warns = []
    static errors = []

    static reportProblem() {

        html2canvas(document.body).then(function (canvas) {
            console.log(canvas.toDataURL())
        });
    }

    static simulate(element, eventName) {
        console.log(eventName)
        var options = Util.extend(Util.defaultOptions, arguments[2] || {});
        var oEvent, eventType = null;

        for (var name in Util.eventMatchers) {
            if (Util.eventMatchers[name].test(eventName)) { eventType = name; break; }
        }

        if (!eventType)
            throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

        if (document.createEvent) {
            oEvent = document.createEvent(eventType);
            if (eventType == 'HTMLEvents') {
                oEvent.initEvent(eventName, options.bubbles, options.cancelable);
            }
            else {
                oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable, document.defaultView,
                    options.button, options.pointerX, options.pointerY, options.pointerX, options.pointerY,
                    options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element);
            }
            element.dispatchEvent(oEvent);
        }
        else {
            options.clientX = options.pointerX;
            options.clientY = options.pointerY;
            var evt = document.createEventObject();
            oEvent = Util.extend(evt, options);
            element.fireEvent('on' + eventName, oEvent);
        }
        return element;
    }

    static extend(destination, source) {
        for (var property in source)
            destination[property] = source[property];
        return destination;
    }

    static eventMatchers = {
        'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
        'MouseEvents': /^(?:click|dblclick|mouse(?:down|up|over|move|out))$/
    }

    static defaultOptions = {
        pointerX: 0,
        pointerY: 0,
        button: 0,
        ctrlKey: false,
        altKey: false,
        shiftKey: false,
        metaKey: false,
        bubbles: true,
        cancelable: true
    }

    //retorna true se os arrays forem iguais
    static compareArrays(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }
}

