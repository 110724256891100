<template>
<div>
  <div class="layout-footer">
    <img alt="Logo" :src="footerImage()" height="30" class="mr-2" />
    <div style="margin-left: 5px">by</div>
    <span class="font-medium ml-2">Operational Risk Team</span>
    </div>
    <span style="float: right" class="font-medium ml-2"
      >Version: {{ version }}</span
    >
  </div>
</template>

<script>
import Util from './service/Util';


export default {
  name: "AppFooter",
  data() {
    return {
      version:""
    };
  },
  mounted() {
    this.getVersion();
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme
        ? "images/logo_only_storm_white_font.png"
        : "images/logo_only_storm_black_font.png";
    },
    getVersion(){
      fetch("data/versions.txt?v="+Math.floor(Math.random() * 1000000)).then(response => response.text())
      .then(text => {
        let lines = text.split('\n')
        let lastLine = lines[lines.length - 2]
        this.version = lastLine
        Util.version = this.version
      })
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>

























































































