import BackendService from './BackendService';
import IncidentService from './IncidentService';

export default class UserService {

    static user = null
    static roles = []
    static roUsers = []

    static getUser() {
        return BackendService.get("/core/user/").then(resp => {
            UserService.user = resp.data["mail"].toLowerCase()
            UserService.roles = resp.data["roles"]

        })
    }

    static getRoUsers() {
        IncidentService.incidentForReviewCount().then((response) => {
            UserService.roUsers = Object.keys(response)
        })
    }

    static checkUserRoleAny(role) {
 
        if (UserService.roles.includes("admin")) {
            return true
        }
       
        for (let element of role) {
            if (UserService.roles.includes(element)) {
                return true;
            }
        }
 
        return false
    }

    static checkUserRoleAll(roles) {
        if (UserService.roles.includes("admin")) {
            return true;
        }
        
        for (let role of roles) {
            if (!UserService.roles.includes(role)) {
                return false;
            }
        }
    
        return true;
    }
}
