import BackendService from './BackendService';


export default class FieldService {

    static data = {}

    static fetch(lang) {
        console.log(lang)

        return BackendService.get("/fields/field/?lang="+lang).then(resp => {
            console.log(resp.data)
            FieldService.data = resp.data
        })
    }

}