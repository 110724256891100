export default class TokenService {

    static token = ""

    static getToken() {
        TokenService.token = localStorage.getItem("token")
    }
}


