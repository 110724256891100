<template>
  <div v-if="isReady == 4" :class="containerClass" @click="onWrapperClick">
    <Toast />
    <div v-if="displayLoading">
      <Dialog :showHeader="false" :closeOnEscape="false" :closable="false" :modal="true" v-model:visible="displayLoading"
        :draggable="false" class="card text-center justify-content-center loading-dialog"
        style="width: 160px; height: 160px">
        <div>
          <img src="images/logo_only_storm_black_font.png" height="90" style="margin: 0px" class="pulsing-image" />
          <h6 style="margin-bottom: 0px">
            Loading
            <i
              class="scalein animation-ease-in-out animation-duration-1000 animation-delay-200 animation-iteration-infinite">.</i>
            <i
              class="scalein animation-ease-in-out animation-duration-1000 animation-delay-300 animation-iteration-infinite">.</i>
            <i
              class="scalein animation-ease-in-out animation-duration-1000 animation-delay-400 animation-iteration-infinite">.</i>
          </h6>
        </div>
      </Dialog>
    </div>
    <div v-if="displayForceUpdate">
      <Dialog :closeOnEscape="false" :closable="false" :modal="true" v-model:visible="displayForceUpdate"
        :draggable="false" class="card text-center justify-content-between flex-column flex">
        <template #header>
          <b>{{ this.$langGetValue("stormNewVersionAvailable") }}<br />{{
            this.$langGetValue("clickToUpdatePage")
          }}</b>
        </template>
        <Button class="mt-2" @click="hardReload()">OK</Button>
      </Dialog>
    </div>
    <AppTopBar v-if="!onlyMainContainer" @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick" v-if="!onlyMainContainer">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div v-if="displayRoleReauthenticate">
      <Dialog :showHeader="false" :closeOnEscape="false" :closable="false" :modal="true"
        v-model:visible="displayRoleReauthenticate" :draggable="false"
        class="card text-center justify-content-center loading-dialog" style="width: 400px; height: 350px; margin: ">
        <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100"
          style="margin-bottom: 10px" />
        <div class="mt-3" style="margin: 15px">
          <h4 style="font-weight: bold">
            {{ this.$langGetValue("reauthenticateRoles") }}
          </h4>
          <p style="font-size: small">
            {{ this.$langGetValue("changeRolesTextBody") }}
          </p>
        </div>
        <template #footer>
          <div class="text-center mt-2">
            <Button icon="pi pi-clock" :label="this.$langGetValue('postoneReauthenticate')"
              style="width: 170px; background-color: red" class="p-button p-component p-button-warning"
              @click="displayRoleReauthenticate = false" />
            <Button icon="pi pi-refresh" :label="this.$langGetValue('refreshToken')" style="width: 170px"
              class="p-button p-component p-button-success" @click="refreshToken()" :disabled="tweetReplaceButton" />
          </div>
        </template>
      </Dialog>
    </div>

    <div class="layout-main-container" ref="main-container">
      <div class="layout-main" style="padding: 0 1% 0 1%">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      @layout-change="onLayoutChange"
      @change-theme="changeTheme"
    /> -->
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import IncidentService from "./service/IncidentService.js";
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import Util from "./service/Util.js";
import UserService from "./service/UserService.js";
// import TokenService from "./service/TokenService.js";
import AnalyticsService from "./service/AnalyticsService";
import FieldService from "./service/FieldService";
import router from "./router.js";
import ContentService from "./service/ContentService.js";
import AuthService from "./service/AuthService.js";
import { Vue3Lottie } from "vue3-lottie";
import thunderAnimation from "./assets/animations/AnimacaoThunder.json";

export default {
  emits: ["change-theme"],
  setup() { },
  data() {
    return {
      displayForceUpdate: false,
      isReady: 0,
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      displayLoading: true,
      displayRoleReauthenticate: false,
      menu: [],
      onlyMainContainer: false,
      thunderAnimation,
      menuClick: true,
      menuIsOpen: false,
      channel: null,
    };
  },
  watch: {
    menuIsOpen() {
      Util.menuIsOpen = this.menuIsOpen;
    },
    $route() {
      this.$toast.removeAllGroups();
    },
    // displayLoading() {
    //   if (this.displayLoading === true) {
    //     console.log("Entrouuu")
    //     setTimeout(() => {
    //       if (this.displayLoading === true) {
    //         Util.loading = false; 
    //         this.$toast.add({
    //           severity: "error",
    //           summary: this.$langGetValue("toast6"), 
    //           detail: this.$langGetValue("toast6Sub"),
    //           life: 9000, 
    //         });
    //       }
    //     }, 100000); 
    //   }
    // },
  },
  methods: {
    hardReload() {
      window.location.reload(true);
    },
    checkVersionFront() {
      if (
        !window.location.href.includes("localhost") &&
        !window.location.href.includes("dev") &&
        Util.version?.trim()?.length > 3
      ) {
        Util.checkVersion().then((resp) => {
          if (resp.data.trim() != Util.version.trim()) {
            this.displayForceUpdate = true;
          }
        });
      }
    },
    async mountPage() {
      while (ContentService.currentLang === null) {
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      UserService.getRoUsers();

      while (UserService.user === null || UserService.roUsers.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      this.checkRoles();

      AnalyticsService.send(router.currentRoute._value.name, UserService.user);

      IncidentService.getFields().then(() => {
        this.isReady++;
        this.checkForTeams();
      });

      FieldService.fetch(ContentService.currentLang).then(() => {
        this.isReady++;
      });

      Util.getRemoteStorage("pagesize").then(() => {
        this.isReady++;
      })

      this.loadAll();
      this.onMenuToggle();
    },
    checkForTeams() {
      let intervalTeams = setInterval(() => {
        if (this.$route.query.source != undefined) {
          if (this.$route.query.source == "teams") {
            this.onlyMainContainer = true;
            if (this.$refs["main-container"] != undefined) {
              this.$refs["main-container"].style.padding = "20px";
              this.$refs["main-container"].style.margin = "0px";
              clearInterval(intervalTeams);
            }
          }
        }
      }, 500);
    },
    dropDuplicates(array) {
      const unique = [];
      const to = new Set();

      for (let i = array.length - 1; i >= 0; i--) {
        const item = array[i];
        if (!to.has(item.to)) {
          unique.unshift(item);
          to.add(item.to);
        }
      }
      return unique;
    },
    loadAll() {
      this.mountMenu();
      this.isReady++;

      if (
        !UserService.checkUserRoleAny(["beta-tester"]) &&
        window.location.href.includes("storm-dev")
      ) {
        window.location.href = "https://storm.pactual.net";
      }
    },
    mountMenu() {
      // everybody has incident new and hashtag
      var incidentMenu = {
        label: this.$langGetValue("incident"),
        items: [
          {
            label: this.$langGetValue("menuButtonNew"),
            icon: "pi pi-fw pi-plus",
            to: "/incident/new",
          },
          {
            label: this.$langGetValue("menuButtonSearch"),
            icon: "pi pi-fw pi-search",
            to: "/incident/search",
          },
          // {
          //   label: "Hashtags",
          //   icon: "pi pi-fw pi-hashtag",
          //   to: "/incident/hashtag",
          // },
        ],
      };

      var droMenu = { label: "DRO", items: [] };
      var developerMenu = { label: "Developer", items: [] };
      var issueMenu = { label: "Issue", items: [] };
      var biaMenu = { label: "BIA", items: [] };
      var managerMenu = { label: "Gestão", items: [] };

      //incident
      if (UserService.checkUserRoleAny(["inc.entuba"])) {
        incidentMenu.items.push({
          label: "Entuba",
          icon: "pi pi-fw pi-upload",
          to: "/incident/batch/insert",
        });
      }

      //dro
      if (UserService.checkUserRoleAny(["dro.admin", "dro.user"])) {
        droMenu.items.push({
          label: "Envio de arquivos",
          icon: "pi pi-fw pi-upload",
          to: "/dro-file/upload",
        });
      }

      if (UserService.checkUserRoleAny(["dro.admin"])) {
        droMenu.items.push({
          label: "Gerenciamento de arquivos",
          icon: "pi pi-fw pi-file",
          to: "/dro-manager-files",
        });
      }
      if (UserService.checkUserRoleAny(["dro.admin"])) {
        droMenu.items.push({
          label: "Administração",
          icon: "pi pi-fw pi-user",
          to: "/dro-manager",
        });
      }
      if (UserService.checkUserRoleAny(["dro.admin", "dro.user"])) {
        droMenu.items.push({
          label: "Manual DRO",
          icon: "pi pi-fw pi-book",
          to: "/dro-handbook",
        });
      }

      // developer
      if (UserService.checkUserRoleAny(["developer"])) {
        developerMenu.items.push({
          label: "Postdog",
          icon: "pi pi-fw pi-code",
          to: "/postdog",
        });
      }

      // issue
      if (UserService.checkUserRoleAny(["issue.user"])) {
        issueMenu.items.push({
          label: this.$langGetValue("menuButtonNew"),
          icon: "pi pi-fw pi-plus",
          to: "/issue/new",
        });
        issueMenu.items.push({
          label: this.$langGetValue("menuButtonSearch"),
          icon: "pi pi-fw pi-search",
          to: "/issue/search",
        });
      }

      // bia
      if (UserService.checkUserRoleAny(["bia.manager"])) {
        biaMenu.items.push({
          label: this.$langGetValue("biaMenu2"),
          icon: "pi pi-fw pi-plus",
          to: "/new-bia",
        });
      }

      if (UserService.checkUserRoleAny(["bia.manager", "bia.user"])) {
        biaMenu.items.push({
          label: this.$langGetValue("biaMenu1"),
          icon: "pi pi-fw pi-list",
          to: "/bia",
        });
      }

      if (UserService.checkUserRoleAny(["bia.manager"])) {
        biaMenu.items.push({
          label: this.$langGetValue("menuButtonSearch"),
          icon: "pi pi-fw pi-search",
          to: "/bia/search/form",
        });
      }

      // manager

      if (UserService.checkUserRoleAny(["role.manager"])) {
        managerMenu.items.push({
          label: "Roles",
          icon: "pi pi-fw pi-lock",
          to: "/manager/roles",
        });
      }

      droMenu.items = this.dropDuplicates(droMenu.items);
      biaMenu.items = this.dropDuplicates(biaMenu.items);
      managerMenu.items = this.dropDuplicates(managerMenu.items);

      this.menu.push({
        items: [
          {
            label: "Home",
            icon: "pi pi-home",
            to: "/",
          },
        ],
      });

      if (incidentMenu.items.length > 0) {
        this.menu.push(incidentMenu);
      }
      if (droMenu.items.length > 0) {
        this.menu.push(droMenu);
      }
      if (issueMenu.items.length > 0) {
        this.menu.push(issueMenu);
      }
      if (biaMenu.items.length > 0) {
        this.menu.push(biaMenu);
      }
      if (developerMenu.items.length > 0) {
        this.menu.push(developerMenu);
      }
      if (managerMenu.items.length > 0) {
        this.menu.push(managerMenu);
      }
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      this.menuIsOpen =
        this.overlayMenuActive ||
        this.mobileMenuActive ||
        !this.staticMenuInactive;
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;

        this.menuIsOpen = false;
      }
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
    syncLoading() {
      this.displayLoading = Util.loading;
      // console.log(this.displayLoading)
    },
    checkRoles() {
      AuthService.getUserRoles(UserService.user).then((response) => {
        if (response.success) {
          if (!Util.compareArrays(response.data, UserService.roles)) {
            this.displayRoleReauthenticate = true;
          }
        }
      });
    },
    refreshToken() {
      AuthService.reautenticate();
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  mounted() {
    // verifica roles a cada 15 min
    setInterval(() => this.checkRoles(), 15 * 60 * 1000);

    setTimeout(() => {
      setInterval(() => this.syncLoading(), 1);
    }, 0);

    setTimeout(() => {
      setInterval(() => this.checkVersionFront(), 6000);
    }, 1000);

    this.mountPage();

    this.channel = new BroadcastChannel("broadcast_channel");

    this.channel.addEventListener("message", (event) => {
      if (event.data.action === "reautenticatePages") {
        setTimeout(() => this.hardReload(), 1000);
      }
    });

    if (localStorage.getItem("reload") == "true") {
      localStorage.removeItem("reload");
      setTimeout(() => this.channel.postMessage({ action: "reautenticatePages" }), 1000);
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
    Vue3Lottie,
  },
};
</script>

<style lang="scss">
@import "./App.scss";

.pulsing-image {
  animation: pulse 7s infinite;
  transition: transform 0.01s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.7);
  }
}

.loading-dialog {
  padding: 0px;
}

.loading-dialog .p-dialog-content {
  padding: 0px;
}

body {
  background-color: white;
}
</style>
