<template>
  <div class="layout-topbar">
    
    <div style="width: 33%; justify-content: start; display: flex; align-items: center; gap: 24px;" class="text-left">
      
      <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle"
        style="margin: 0px"
      >
        <i class="pi pi-bars"></i>
      </button>
      
    </div>

    <div class="text-center">
      <router-link to="/" class="layout-topbar-logo">
        <div v-if="veirifyIsDev()">Ambiente de Desenvolvimento</div>
        <img v-else alt="Logo" :src="topbarImage()" style="margin-right: 0px" />
      </router-link>
    </div>

    <div style="width: 33%; align-items: center; gap: 5px;" class="flex justify-content-end">
      <!-- <div class="flex">
        <country-flag
          style="cursor: pointer"
          @click="changeLang('pt_br')"
          country="bra"
          size="normal"
        />
      </div>
      <div class="flex">
        <country-flag
          style="cursor: pointer"
          @click="changeLang('en_us')"
          country="us"
          size="normal"
        />
      </div>
      <div class="flex" v-if="checkRole(['beta-tester'])">
        <country-flag
          style="cursor: pointer"
          @click="changeLang('es_es')"
          country="es"
          size="normal"
        />
      </div> -->

      <div class="flex">
        <img
          src="/images/flags/bra.jpg"
          alt="Flag of Brazil"
          style="cursor: pointer; width: 26px; height: 18px; margin-top: 10px"
          @click="changeLang('pt_br')"
        />
      </div>
      <div class="flex">
        <img
          src="/images/flags/usa.jpg"
          alt="Flag of the United States"
          style="cursor: pointer; width: 26px; height: 18px; margin-top: 10px"
          @click="changeLang('en_us')"
        />
      </div>
      <div class="flex">
        <img
          src="/images/flags/es.jpg"
          alt="Flag of Spain"
          style="cursor: pointer; width: 26px; height: 18px; margin-top: 10px"
          @click="changeLang('es_es')"
        />
      </div>
      <div class="nameSection ml-4" style="display: flex; cursor: pointer;" @click="toggle">
        <div style="display: flex; flex-direction: row; gap: 8px; padding: 6px 12px; align-items: center; margin-top: 8px" class="primaryButton" >
          <i class="pi pi-question" style="width: 16px; height: 16px;"></i>
          {{this.$langGetValue('labelGuide')}}
        </div>
        <TieredMenu
          ref="menu"
          id="overlay_tmenu"
          :model="menuItems"
          popup
          style="margin-top: 8px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentService from "./service/ContentService";
import UserService from "./service/UserService";
import Util from "./service/Util";
import "./Global.css"

export default {
  data() {
    return {
      user: UserService.user,
      menuItems: [],
    };
  },
  mounted() {
    this.user = UserService.user;

    this.menuItems = [
      {
        label: this.$langGetValue('stormGuide'),
        icon: "pi pi-book",
        command: () => {
          const pdfPath = '/guides/Guia do Usuário_STORM.pdf';
          window.open(pdfPath, '_blank');
          window.location.reload();
        },
      }
    ];
  },
  methods: {
    checkRole(roles) {
      return UserService.checkUserRoleAny(roles)
    },
    toggle(event) {
      if (this.$refs.menu) {
        this.$refs.menu.toggle(event);
      }
    },
    getUserName() {
      return Util.emailToName(this.user);
    },
    getImageRoute() {
      let url = window.location.origin;
      if (url.includes("localhost")) {
        return (
          "https://storm-dev.pactual.net/api/storm/lara/user/" +
          this.user +
          "/photo"
        );
      } else {
        return url + "/api/storm/lara/user/" + this.user + "/photo";
      }
    },
    veirifyIsDev() {
      if (
        window.location.href.includes("storm-dev") ||
        window.location.href.includes("localhost")
      ) {
        return true;
      }
      return false;
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo_only_storm_white_font.png"
        : "images/logo_only_storm_black_font.png";
    },
    changeLang(lang) {
      ContentService.setLang(lang);
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>

<style>
.layout-topbar .layout-topbar-logo {
  display: block;
  align-items: center;
  color: var(--surface-900);
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 12px;
  width: fit-content;
  order: 2;
}

.flagDiv .flag {
  width: 52px;
  height: 39px;
  display: inline-block;
}

.profileTriggerDiv {
  cursor: pointer;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  font-size: 12px;
  color: var(--color_brand_dark);
  font-weight: 600;
}

.p-tieredmenu .p-menuitem-link {
  display: flex;
  justify-content: start;
  padding: 16px 16px;
}

.p-tieredmenu.p-tieredmenu-overlay{
  width: fit-content;
}

.p-tieredmenu .p-menuitem{
  width: 100%;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: var(--color_brand_dark);;
}

.flag {
  width: 52px;
  height: 39px;
  cursor: pointer;
}
</style>
