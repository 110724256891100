import BackendService from './BackendService';
import Util from './Util';


export default class AnalyticsService {

    static send(page, user, extraFields) {

        extraFields = extraFields != null && Object.keys(extraFields).length > 0  ? '?' + Util.encodeQueryData(extraFields) : ''
        
        return BackendService.get("sso/analytics/" + page + "/" + user + extraFields).then(resp => {
            return resp.data
        })
    }

}